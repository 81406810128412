/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import Button from "../../shared/button";
import TransparentButton from "../../shared/button/transparent-button";
// import NudgeCar from "./images/nudge-car.png";
import { getCarDetailsPageRelURL, getCheckoutPageURL, getMyBookingCarDetailsURL, getWishlistURL } from "../../../utils/helpers/get-detail-page-url";
import { getCheckoutPageURL as getSellYourCarURL, getBookInspectionPageURL} from "../../../au-c2b/utils/helpers/get-detail-page-url";
import { useHistory } from "react-router";
import { fastlyUrl, imageTypes } from "../../../utils/helpers/cdn-image-url";
import { AU_DESKTOP_EVENTS } from "../../../tracking/au.desktop-events";
import { trackDesktopCustomEventsAU } from "../../../tracking";
import { BOOKING_CONFIRMED, BOOKING_INITIATED, CHECKOUT_STEPS_PATH, MULTI_WISHLIST, TEST_DRIVE_BOOKED, TEST_DRIVE_BOOKING_ROUTE, WISHLIST, TEST_DRIVE, TRADEIN_OFFER_EXPIRED, INSPECTION_PENDING, VALUATION_PENDING } from "../../../constants/checkout-constants";
import { checkoutRoutes } from "../../../constants/au.desktop/checkout-routes";
import { STEP_ROUTE_MAP, INSPECTION_ROUTE_MAP } from "../../../au-c2b/constants/app-constants";
import ValuationExpiredPopup from "../valuation-expired-popup";
// import CARIMG from "./images/carimg.svg";
import WISHLISTICON from "./images/wishlist.gif";
import HEARTIMG from "./images/heart.svg";
import DANGER from "./images/danger.svg";
import INFOIMG from "./images/right.svg";
import CAR from "./images/car.png";
import { ENABLE_NEW_POST_BOOK_FLOW } from "../../../constants/app-constants";
import { postPaymentRoutes } from "../../../constants/au.desktop/post-payment-routes";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import { tasksRoutes } from "../../../constants/au.desktop/tasks-routes";
import { yieldToMain } from "../../../utils/helpers/yield-to-main";

const NudgeCards = ({
    secureToken,
    nudgeList = [],
    fetchNudgeListConnect,
    setNudgeDisplayConnect,
    closedNudgesId,
    closeNudgeListConnect
}) => {
    const history = useHistory();
    useEffect(() => {
        if (secureToken) {
            fetchNudgeListConnect(secureToken);
        }
    }, [secureToken]);

    const getBookingRoute = (content) => {
        if (content.sourceAlertType === TEST_DRIVE_BOOKED && content.parentSourceAlertType === TEST_DRIVE) {
            return getMyBookingCarDetailsURL(content.appointmentId, TEST_DRIVE_BOOKING_ROUTE);
        } else if (ENABLE_NEW_POST_BOOK_FLOW[content.sourceAlertType]) {
            return getMyBookingCarDetailsURL(content.appointmentId, postPaymentRoutes.addOns.route, null, EXPERIMENT_TYPE.VARIANT_B);
        } else {
            switch (content.parentSourceAlertType) {
            case TEST_DRIVE_BOOKED:
                return getMyBookingCarDetailsURL(content.appointmentId, TEST_DRIVE_BOOKING_ROUTE);
            case MULTI_WISHLIST :
                return getWishlistURL(); // convert to object
            case WISHLIST:
                return getCarDetailsPageRelURL(content.vehicleDetail);
            case BOOKING_INITIATED:
                return getCheckoutPageURL(content.vehicleDetail, checkoutRoutes[CHECKOUT_STEPS_PATH[content.step]].route);
            case INSPECTION_PENDING:
                return getBookInspectionPageURL(INSPECTION_ROUTE_MAP[content.step], content.orderId);
            case VALUATION_PENDING:
                return getSellYourCarURL(STEP_ROUTE_MAP[content.step], content.orderId);
            default: return getMyBookingCarDetailsURL(content.appointmentId, tasksRoutes.addOns.route);
            }
        }
    };
    // eslint-disable-next-line complexity
    const trackGAEvents = (item, cancel = false) => {
        if (item.parentSourceAlertType === WISHLIST) {
            return trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.RESUME_JOURNEY_WISHLIST, {
                eventLabel: `${cancel ? "cancel" : "get_started"}`
            });
        } else if (item.parentSourceAlertType === MULTI_WISHLIST) {

            return trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.RESUME_JOURNEY_WISHLIST, {
                eventLabel: `${cancel ? "cancel" : "view"}`
            });
        } else if (item.parentSourceAlertType === TEST_DRIVE) {
            return trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.RESUME_JOURNEY_TESTDRIVE, {
                eventLabel: `${cancel ? "cancel" : "view"}`
            });
        } else if (item.parentSourceAlertType === BOOKING_INITIATED) {
            return trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.RESUME_JOURNEY_BUYING, {
                eventLabel: `${cancel ? "cancel" : "resume" }`
            });
        } else if (item.parentSourceAlertType === BOOKING_CONFIRMED) {
            return trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.RESUME_JOURNEY_BC, {
                eventLabel: `${cancel ? "cancel" : "view"}`
            });
        } else if (item.parentSourceAlertType === INSPECTION_PENDING) {
            return trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.SELL_ORDER_BOOK_INSPECTION_NUDGE, {
                eventLabel: `${cancel ? "cancel" : "resume" }`
            });
        } else if (item.parentSourceAlertType === VALUATION_PENDING) {
            return trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.SELL_ORDER_VALUATION_NUDGE, {
                eventLabel: `${cancel ? "cancel" : "view"}`
            });
        }
        return null;
    };

    const handleAlertAction = async (index, id, item) => {
        setNudgeDisplayConnect(id);
        const {appointmentId, parentSourceAlertType, vehicleDetail, step, sourceAlertType, orderId} = nudgeList[index] || {};
        const carContent = {appointmentId, parentSourceAlertType, vehicleDetail, step, sourceAlertType, orderId};
        const { relativeURL } =  getBookingRoute(carContent);
        history.push(relativeURL);
        await yieldToMain();
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.NUDGE_CARD, {
            eventLabel: `Option ${item.actionCta}`
        });
        trackGAEvents(item);
    };

    const handleCloseNudge = async (id, item) => {
        const { appointmentId,  sourceAlertType, manualSuppress, parentSourceAlertType, eligibleAppointmentIdsToSuppress} = item || {};
        if (manualSuppress) {
            closeNudgeListConnect(parentSourceAlertType === MULTI_WISHLIST ? eligibleAppointmentIdsToSuppress : [appointmentId], sourceAlertType);
            setNudgeDisplayConnect(id);
        } else {
            setNudgeDisplayConnect(id);
        }
        await yieldToMain();
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.NUDGE_CARD, {
            eventLabel: `Option ${item.actionCta} cancel`
        });
        trackGAEvents(item, true);
    };

    const getImageUrl = (item) => {
        const { vehicleDetail = {} } = item || {};
        const { config = {}, mainImage = {}, frontImageUrl = null} = vehicleDetail || {};
        const carImageUrl = config && mainImage.path && config.imageHost
            ? fastlyUrl(config.imageHost, mainImage.path, imageTypes.auNudgesMedium)
            : frontImageUrl;
        return carImageUrl;
    };

    const renderNudgeImage = (item) => {
        // const carImage = getImageUrl(item);
        if (item.parentSourceAlertType === WISHLIST) {
            return HEARTIMG;
        }  else if (item.parentSourceAlertType === BOOKING_CONFIRMED && item.parentSourceAlertType === TEST_DRIVE_BOOKED) {
            return  DANGER;
        } if (item.parentSourceAlertType === BOOKING_CONFIRMED) {
            return DANGER;
        } else if (item.parentSourceAlertType === TEST_DRIVE_BOOKED) {
            return INFOIMG;
        }
        return false;
    };

    const handleCloseValuationExpiredNudge = (item) => {
        const { id = "" } = item || {};
        closeNudgeListConnect([], TRADEIN_OFFER_EXPIRED);
        setNudgeDisplayConnect(id);
    };

    const renderNudgeCard = (item, index) => {
        if (!(closedNudgesId || []).filter((id) => id === item.id).length) {
            const carImage = getImageUrl(item);
            const carIcon = renderNudgeImage(item);

            return (
                <React.Fragment>
                    {
                        (item.sourceAlertType === TRADEIN_OFFER_EXPIRED && item.source === "TRADE_IN") ?
                            <ValuationExpiredPopup
                                item={item}
                                handleClose={handleCloseValuationExpiredNudge}
                            />
                            :
                            <div styleName={"styles.wrapper"}>
                                <div className="media">
                                    {item.parentSourceAlertType !== MULTI_WISHLIST ?
                                        <div styleName={"styles.carimginitial"}>
                                            <img styleName={"styles.caricon"} src={carImage || CAR} />
                                            {carIcon && <img styleName={"styles.heart"} src={carIcon} />}
                                        </div> :
                                        <div styleName={"styles.imageContainer"}>
                                            <img className="align-self-center" src={WISHLISTICON} />
                                        </div>
                                    }
                                    <div className="media-body">
                                        <p styleName={"styles.heading"}>{item.title}</p>
                                        <p styleName={"styles.detail"}>{item.subTitle}</p>
                                    </div>
                                </div>
                                <div styleName={"styles.buttonContainer"}>
                                    <Button text={item.actionCta} onClick={() => handleAlertAction(index, item.id, item)}/>
                                    <TransparentButton text="CLOSE" onClick={() => handleCloseNudge(item.id, item)}/>
                                </div>
                            </div>
                    }
                </React.Fragment>
            );
        }
        return null;
    };

    return (
        <React.Fragment>
            {(nudgeList || []).map((item, index) => (
                <React.Fragment key={index}>
                    {renderNudgeCard(item, index)}
                </React.Fragment>)
            )}
        </React.Fragment>
    );
};

NudgeCards.propTypes = {
    secureToken: PropTypes.string,
    closedNudgesId: PropTypes.array,
    nudgeList: PropTypes.array,
    fetchNudgeListConnect: PropTypes.func,
    setNudgeDisplayConnect: PropTypes.func,
    isLoggedIn: PropTypes.bool,
    closeNudgeListConnect: PropTypes.func
};
export default NudgeCards;
